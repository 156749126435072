import React, { useState, useContext } from 'react';
import Modal from 'react-modal';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { IconContext } from 'react-icons';
import { RiDownloadLine } from 'react-icons/ri';

import DownloadDisclaimer from 'components/DownloadDisclaimer';
import Button from 'components/Button';
import { breakpoints, layers } from 'lib/constants';
import { svgComponentToDataURI, pxToRem, formatDate, getLinkThemeColor } from 'lib/utils';
import { PoligraficiContext } from 'templates/PostTemplate';

const StyledLink = styled.a`
  display: flex;
  flex-wrap: wrap;
  margin-top: ${pxToRem('14px')};
  padding: ${pxToRem('20px')} 0;
  text-decoration: none;
  border-top: 1px solid;
  border-bottom: 1px solid;

  body.dark & {
    color: ${(props) => props.theme.dark.colors.text};
    border-color: ${(props) => props.theme.dark.colors.separator};
  }

  body.light & {
    color: ${(props) => props.theme.light.colors.text};
    border-color: ${(props) => props.theme.light.colors.separator};
  }

  &:hover {
    body.dark & {
      color: ${(props) => getLinkThemeColor(props.isPoligrafici)};
    }

    body.light & {
      color: ${(props) => getLinkThemeColor(props.isPoligrafici)};
    }
  }

  &.hidden {
    display: none;
  }
`;

const StyledDate = styled.time`
  flex-basis: 100%;

  body.dark & {
    color: ${(props) => props.theme.dark.colors.textOff};
  }

  body.light & {
    color: ${(props) => props.theme.light.colors.textOff};
  }

  @media screen and (min-width: ${pxToRem(breakpoints.large)}) {
    flex-basis: 14%;
  }

  @media screen and (min-width: ${pxToRem(breakpoints.large)}) {
    padding-right: ${pxToRem('20px')};
  }
`;

const StyledText = styled.span`
  flex: 1;
`;

const StyledModalFooter = styled.footer`
  display: flex;
  margin-top: ${pxToRem('24px')};

  @media screen and (max-width: ${pxToRem(breakpoints.large)}) {
    flex-direction: column;
    width: calc(100vw - ${pxToRem('120px')});

    > button {
      display: block;
      width: 100%;
      max-width: ${pxToRem('300px')};
      margin: 0 auto;
    }

    > button + button {
      margin-top: ${pxToRem('15px')};
    }
  }

  @media screen and (min-width: ${pxToRem(breakpoints.large)}) {
    justify-content: center;

    > button + button {
      margin-left: ${pxToRem('15px')};
    }
  }
`;

const StyledModalHeading = styled.h2`
  margin-top: 0;
  font-size: ${pxToRem('20px')};
  font-weight: 700;
  line-height: 1.3;
`;

const DownloadIcon = ({ color }) => (
  <IconContext.Provider value={{ color }}>
    <RiDownloadLine />
  </IconContext.Provider>
);

const StyledExtension = styled.span`
  flex-basis: 8%;
  margin-top: ${pxToRem('4px')};
  padding-right: ${pxToRem('20px')};
  font-size: ${pxToRem('10px')};
  text-align: right;
  text-transform: uppercase;
  background-repeat: no-repeat;
  background-position: right 0 top ${pxToRem('2px')};
  background-size: auto ${pxToRem('14px')};

  body.dark & {
    color: ${(props) => props.theme.dark.colors.textOff};
    ${(props) =>
      `background-image: url("${svgComponentToDataURI(
        <DownloadIcon color={props.theme.dark.colors.textOff} />,
      )}")`};
  }

  body.light & {
    color: ${(props) => props.theme.light.colors.textOff};
    ${(props) =>
      `background-image: url("${svgComponentToDataURI(
        <DownloadIcon color={props.theme.light.colors.textOff} />,
      )}")`};
  }
`;

const StyledCompany = styled.em`
  flex-basis: 100%;

  body.dark & {
    color: ${(props) => props.theme.dark.colors.textOff};
  }

  body.light & {
    color: ${(props) => props.theme.light.colors.textOff};
  }

  @media screen and (min-width: ${pxToRem(breakpoints.large)}) {
    flex-basis: 22%;
  }

  @media screen and (min-width: ${pxToRem(breakpoints.large)}) {
    margin-right: ${pxToRem('10px')};
  }
`;

const customStyles = {
  content: {
    maxHeight: `calc(100vh - ${pxToRem('50px')})`,
    color: '#000',
    fontSize: pxToRem('14px'),
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    borderRadius: 0,
    transform: 'translate(-50%, -50%)',
    padding: pxToRem('35px'),
  },
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, .65)',
    zIndex: layers.modal,
  },
};

Modal.setAppElement('#___gatsby');

const Attachment = ({ className, company, file, publishedAt, Data, requireDisclaimer, title }) => {
  // TODO
  /* eslint-disable-next-line */
  let subtitle;
  const { asset = { url: '' } } = file;
  const [modalIsOpen, setIsOpen] = useState(false);
  const isPoligrafici = useContext(PoligraficiContext);

  function openModal(event) {
    setIsOpen(true);
    event.preventDefault();
  }

  function closeModal() {
    setIsOpen(false);
  }

  function handleIsAccepted() {
    window.open(asset.url);
    closeModal();
  }

  return (
    <>
      <StyledLink
        className={className}
        title={title}
        onClick={requireDisclaimer ? openModal : undefined}
        href={requireDisclaimer ? '#' : asset.url}
        isPoligrafici={isPoligrafici}
        rel="noopener noreferrer"
        target="_blank"
      >
        <StyledDate>{formatDate(Data)}</StyledDate>
        {company && <StyledCompany>{company}</StyledCompany>}
        <StyledText>{title}</StyledText>
        <StyledExtension>{file.asset.extension}</StyledExtension>
      </StyledLink>
      {requireDisclaimer && (
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          style={customStyles}
          contentLabel="Attachment Download Disclaimer"
        >
          <StyledModalHeading ref={(_subtitle) => (subtitle = _subtitle)}>
            {title}
          </StyledModalHeading>
          <DownloadDisclaimer />
          <StyledModalFooter>
            <Button isHighlighted onClick={handleIsAccepted}>
              ACCETTO
            </Button>
            <Button onClick={closeModal}>NON ACCETTO</Button>
          </StyledModalFooter>
        </Modal>
      )}
    </>
  );
};

Attachment.propTypes = {
  company: PropTypes.string,
  file: PropTypes.shape({
    asset: PropTypes.shape({
      extension: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  Data: PropTypes.string,
  title: PropTypes.string.isRequired,
};

export default Attachment;
